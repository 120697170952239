import { FC } from 'react';
import { Modal } from 'antd';
import CSVUploadDrag from '../CSVUploadDrag';
import { useIntl } from 'umi';

interface CSVImportModalProps {
  visible: boolean;
  url: string;
  onDone: () => void;
  onCancel: () => void;
}

const CSVImportModal: FC<CSVImportModalProps> = (props) => {
  const { visible, url, onDone, onCancel } = props;
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({id: 'tmp.upload-csv'})}
      width={640}
      destroyOnClose
      visible={visible}
      footer={null}
      onCancel={onCancel}
      centered
    >
      <CSVUploadDrag
        url={url}
        onDone={onDone}
      />
    </Modal>
  );
};

export default CSVImportModal;
