// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/dashboard","parentId":"ant-design-pro-layout","id":"1"},"2":{"path":"/login","name":"login","hideInMenu":true,"headerRender":true,"menuRender":false,"menuHeaderRender":false,"footerRender":false,"parentId":"ant-design-pro-layout","id":"2"},"3":{"path":"/dashboard","name":"dashboard","icon":"smile","parentId":"ant-design-pro-layout","id":"3"},"4":{"name":"shop","icon":"ShopOutlined","path":"/shop/list","parentId":"ant-design-pro-layout","id":"4"},"5":{"name":"image","icon":"PictureOutlined","path":"/image/list","parentId":"ant-design-pro-layout","id":"5"},"6":{"name":"password-change","icon":"KeyOutlined","path":"/password","parentId":"ant-design-pro-layout","id":"6"},"7":{"path":"*","parentId":"ant-design-pro-layout","id":"7"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Login__index" */'@/pages/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Dashboard__index" */'@/pages/Dashboard/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__ShopList__index" */'@/pages/ShopList/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__ImageList__index" */'@/pages/ImageList/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__PasswordChange__index" */'@/pages/PasswordChange/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/home/johnson/lulu-hk-merchant-frontend-web/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
