import { osName, osVersion, mobileVendor, mobileModel, browserName, fullBrowserVersion, deviceType } from 'react-device-detect';
  import { getLocale as _getLocale } from 'umi';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const convertImageUrl = (url: string): string =>
  url.replaceAll('https://merchant-img.luluhk.com', 'https://d2dngwedk8x1f3.cloudfront.net');

export const deviceHeaders = (): Record<string, string> => {
  return {
    appName: "Lulu HK Merchant",
    appVersion: "web",
    os: browserName ?? '',
    osVersion: fullBrowserVersion ?? '',
    deviceBrand: (mobileVendor ?? '') == "none" ? '' : mobileVendor,
    deviceID: '',
    deviceModel: (mobileModel ?? '') == "none" ? '' : mobileModel,
    deviceName: '',
    custom: JSON.stringify({
      os: osName ?? '',
      osVersion: osVersion ?? '',
      deviceType: deviceType ?? '',
    }),
  };
}

export const getLocale = (): string => {
  const locale = _getLocale();
  switch (locale) {
    case 'en-US':
      return 'en';
    case 'zh-CN':
      return 'zh_Hans';
    case 'zh-TW':
      return 'zh_Hant';
    default:
      return 'en';
  }
}

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes == 0)
    return '0 B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
