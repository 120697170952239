import { FC, useState } from 'react';
import styles from './index.less';
import { Upload, message, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useIntl } from 'umi';
import { getLocale } from '@/utils/utils';

interface CSVUploadDragProps {
  url: string;
  onDone: () => void;
}

interface CSVError {
  row: number;
  col: number;
  msg: string;
}

const { Dragger } = Upload;

const CSVUploadDrag: FC<CSVUploadDragProps> = (props) => {
  const { url, onDone } = props;
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [percent, setPercent] = useState<number>(0);
  const [errors, setErrors] = useState<CSVError[]>();
  const intl = useIntl();

  const onChange = (info: any) => {
    const { status, response } = info.file;
    setUploadStatus(status);
    if (status == 'uploading') {
      const event = info.event;
      if (event) {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setPercent(percent);
      }
    }
    if (status === 'done') {
      if (!response.success) {
        message.error(response.errorMessage);
        setUploadStatus('error');
        if (response.data) {
          const { errors } = response.data;
          setErrors(errors);
        }
      } else {
        onDone();
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const errorsList = [];
  if (errors) {
    errors.sort((a, b) => {
      if (a.row == b.row) {
        return a.col - b.col;
      }
      return a.row - b.row;
    });
    for (let i = 0, j = 0; i < errors.length; i = j) {
      let msg = [];
      for (
        ;
        j < errors.length && errors[j].row == errors[i].row && errors[j].col == errors[i].col;
        j++
      ) {
        msg.push(<div key={j}>{errors[j].msg}</div>);
      }
      let col = '';
      if (errors[i].col >= 0) {
        for (let t = errors[i].col;;) {
          col = String.fromCharCode('A'.charCodeAt(0) + t % 26) + col;
          t = Math.floor(t / 26) - 1;
          if (t < 0) break;
        }
      }
      errorsList.push(
        <div key={i}>
          <div className={styles.errorLine}>
            Row {errors[i].row + 1}{col != '' ? `, Column ${col}` : ""}:
          </div>
          <div className={styles.errorMsg}>{msg}</div>
        </div>,
      );
    }
  }

  return (
    <>
      <Dragger
        accept=".csv"
        multiple={false}
        withCredentials={true}
        name="csv"
        action={url + "?locale=" + getLocale()}
        disabled={uploadStatus == 'uploading'}
        onChange={onChange}
        showUploadList={false}
        className={styles.dragger}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{intl.formatMessage({id: 'tmp.upload-csv-hint1'})}</p>
        <p className="ant-upload-hint">{intl.formatMessage({id: 'tmp.upload-csv-hint2'})}</p>
      </Dragger>
      {(uploadStatus == 'uploading' ||
        uploadStatus == 'done' ||
        uploadStatus == 'success' ||
        uploadStatus == 'error') && (
          <Progress
            className={styles.progress}
            strokeLinecap="square"
            percent={percent}
            status={
              uploadStatus == 'uploading'
                ? 'active'
                : uploadStatus == 'error'
                  ? 'exception'
                  : undefined
            }
          />
        )}
      {errors && errorsList}
    </>
  );
};

export default CSVUploadDrag;
