import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { history, useIntl, useModel } from '@umijs/max';
import { message, notification, Spin } from 'antd';
import type { MenuProps } from 'antd';
import { stringify } from 'querystring';
import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import HeaderDropdown from '../HeaderDropdown';
import { accountLogout } from '@/services/account';
import CSVImportModal from '../CSVImportModal';

export type GlobalHeaderRightProps = {
  menu?: boolean;
  children?: React.ReactNode;
};

export const AvatarName = () => {
  const { initialState } = useModel('@@initialState');
  const { currentAccount } = initialState || {};
  return <span className="anticon">{(currentAccount && (currentAccount.ownerShops?.length>0||currentAccount.mallShops?.length>0)) ? currentAccount.name['en'] : currentAccount?.username}</span>;
};

export const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu, children }) => {
  /**
   * 退出登录，并且将当前的 url 保存
   */
  const [importURL, setImportURL] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const intl = useIntl();
  const loginOut = async () => {
    try {
      await accountLogout();
    } catch (_) {
      notification.error({
        description: intl.formatMessage({id: 'tmp.accessTokenExpired'}),
        message: intl.formatMessage({id: 'tmp.accessTokenExpiredTitle'}),
      });
    }  
    const { search, pathname } = window.location;
    const urlParams = new URL(window.location.href).searchParams;
    const redirect = urlParams.get('redirect');
    if (window.location.pathname !== '/login' && !redirect) {
      history.replace({
        pathname: '/login',
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }
  };

  const { initialState, setInitialState } = useModel('@@initialState');

  const handleImport = async () => {
    setImportURL(`/api/csv/import/merchant`);
    setVisible(true);
  };

  const handleOnDone = () => {
    message.success(intl.formatMessage({id: 'tmp.update-success'}));
    setVisible(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onMenuClick: MenuProps['onClick'] = (event) => {
    const { key } = event;
    if (key === 'logout') {
      flushSync(() => {
        setInitialState((s) => ({ ...s, currentAccount: undefined }));
      });
      loginOut();
      return;
    }
    if (key == 'import') {
      handleImport();
      return;
    }
    if (key == 'export') {
      return;
    }
    history.push(`/account/${key}`);
  };

  const loading = (
    <span className={'action'}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentAccount } = initialState;

  if (!currentAccount || !currentAccount.name) {
    return loading;
  }

  let isOwnerOrMall = false;
  if(currentAccount.ownerShops.length>0||currentAccount.mallShops.length>0){
    isOwnerOrMall = true;
  }

  const menuItems = [
    ...(menu
      ? [
          {
            key: 'center',
            icon: <UserOutlined />,
            label: '个人中心',
          },
          {
            key: 'settings',
            icon: <SettingOutlined />,
            label: '个人设置',
          },
          {
            type: 'divider' as const,
          },
        ]
      : []),
    ...(isOwnerOrMall
        ? [
          {
            key: 'import',
            label: intl.formatMessage({id: 'tmp.import.merchant'}),
          },
          {
            key: 'export',
            label: <a href={`/api/csv/export/merchant`} target="_blank">{intl.formatMessage({id: 'tmp.export.merchant'})}</a>,
          },
        ]: []),
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: intl.formatMessage({id: 'tmp.logout'}),
    },
  ];

  return (
    <><HeaderDropdown
      menu={{
        selectedKeys: [],
        onClick: onMenuClick,
        items: menuItems,
      }}
    >
      {children}
    </HeaderDropdown><CSVImportModal visible={visible} url={importURL} onCancel={handleCancel} onDone={handleOnDone} /></>
  );
};
