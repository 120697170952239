import { AvatarDropdown, AvatarName, Footer, SelectLang, SelectMode } from '@/components';
import { type Settings as LayoutSettings } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig, RuntimeAntdConfig } from '@umijs/max';
import { history } from '@umijs/max';
import React from 'react';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import { accountInfo } from './services/account';
import { convertImageUrl } from './utils/utils';
import './global.css';
import ReactGA from 'react-ga4';

const loginPath = '/login';

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentAccount?: API.Account;
  loading?: boolean;
  fetchAccountInfo?: () => Promise<API.Account | undefined>;
}> {
  const fetchAccountInfo = async () => {
    try {
      const resp = await accountInfo();
      const currentAccount: API.Account = resp.data;
      return currentAccount;
    } catch (error) {
      history.replace(loginPath);
    }
    return undefined;
  };

  // 如果不是登录页面，执行
  const { location } = history;
  if (location.pathname !== loginPath) {
    const currentAccount = await fetchAccountInfo();
    return {
      fetchAccountInfo,
      currentAccount,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }
  return {
    fetchAccountInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

export const antd: RuntimeAntdConfig = () => {
  const nonce = localStorage.getItem('nonce') || 'default_nonce';
  return {
    csp: {
      nonce: nonce,
    }
  }
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  return {
    actionsRender: () => [<SelectMode key="SelectMode" />, <SelectLang key="SelectLang" />],
    headerTitleRender: initialState?.currentAccount ? undefined : false,
    menuHeaderRender: initialState?.currentAccount ? undefined : false,
    avatarProps: {
      src: (initialState?.currentAccount && (initialState?.currentAccount.ownerShops.length>0||initialState?.currentAccount.mallShops.length>0)) ? convertImageUrl((initialState?.currentAccount?.avatar && initialState?.currentAccount?.avatar != "")  ? initialState?.currentAccount?.avatar : "https://merchant-img.luluhk.com/luluhk/profilephoto.jpg") : null,
      title: (initialState?.currentAccount && (initialState?.currentAccount.ownerShops.length>0||initialState?.currentAccount.mallShops.length>0)) ? <AvatarName /> : null,
      render: (_, avatarChildren) => {
        return initialState?.currentAccount ? <AvatarDropdown>{avatarChildren}</AvatarDropdown> : null;
      },
    },
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentAccount && location.pathname !== loginPath) {
        history.replace(loginPath);
      }
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    },
    bgLayoutImgList: initialState?.currentAccount ? [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ] : [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    links: [],
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      //if (initialState?.loading) return <PageLoading />;
      return (
        <>
          {children}
        </>
      );
    },
    ...initialState?.settings,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};
