import component from './en-US/component';
import globalHeader from './en-US/globalHeader';
import menu from './en-US/menu';
import pages from './en-US/pages';
import pwa from './en-US/pwa';
import settingDrawer from './en-US/settingDrawer';
import settings from './en-US/settings';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  'app.name': 'Lulu HK Merchant',
  'tmp.language.english': 'English',
  'tmp.language.zh_Hans': '简体中文',
  'tmp.language.zh_Hant': '繁體中文',
  'tmp.welcome': 'Welcome!',
  'tmp.dashboard': 'Dashboard',
  'tmp.captcha.title': 'Security Check (Click on the image to try another code)',
  'tmp.captcha.placeholder': 'Security Check',
  'tmp.captcha.hint': 'Please input the code',
  'tmp.captcha.change.fail': 'Failed to generate new CAPTCHA',
  'tmp.login.success': 'Login Successful',
  'tmp.login.fail': 'Login Failed',
  'tmp.logout': 'Log Out',
  'tmp.clear': 'Clear',
  'tmp.confirm': 'Confirm',
  'tmp.okay': 'OK',
  'tmp.bracket-open': ' (',
  'tmp.bracket-close': ') ',
  'tmp.no-permission': 'No permission',
  'tmp.upload-title': 'Upload',
  'tmp.upload-fail': "Upload failed: ",
  'tmp.upload-error-unexpected': "an unexpected error occurred",
  'tmp.upload-success': " uploaded successfully",
  'tmp.update-success': "Updated successfully!",
  'tmp.password-change.success': "Changed successfully!",
  'tmp.password-change.fail': "Failed to change password",
  'tmp.password-change.new-not-match': "New passwords don't match",
  'tmp.password-change.old-password.placeholder': "Current Password",
  'tmp.password-change.new-password.placeholder': "New Password",
  'tmp.password-change.confirm-new-password.placeholder': "Confirm New Password",
  'tmp.password-change.must-fill': "This is required",
  'tmp.password-change.merchant-password-invalid': "Password must have at least 8 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.",
  'tmp.password-change.password-same-as-old': "New Password cannot be the same as Current Password",
  'tmp.password-change.wrong-password': "Wrong Password",
  'tmp.timepostedon': "Posted on",
  'tmp.accessTokenExpired': "Session expired. Please log in again.",
  'tmp.accessTokenExpiredTitle': "Session expired",
  'tmp.pageNotFound': "Oops! Page not found.",
  'tmp.goBackHome': "Go back home",
  'tmp.csv-merchant.msg': 'Merchant CSV Guide (English) v1.0',
  'tmp.csv-shop.msg': 'Shop CSV Guide (English) v1.0',
  'tmp.csv-product.msg': 'Product CSV Guide (English) v1.0',
  'tmp.csv-merchant.link': 'https://docs.luluhk.com/Merchant CSV Import & Export (English).pdf',
  'tmp.csv-shop.link': 'https://docs.luluhk.com/Shop CSV Import & Export (English).pdf',
  'tmp.csv-product.link': 'https://docs.luluhk.com/Product CSV Import & Export (English).pdf',
  'tmp.import-export': 'Import/Export',
  'tmp.import.merchant': 'Import Merchant Info',
  'tmp.import.shop': 'Shop Import',
  'tmp.import.product': 'Product Import',
  'tmp.export.merchant': 'Export Merchant Info',
  'tmp.export.shop': 'Shop Export',
  'tmp.export.product': 'Product Export',
  'tmp.shop-list': 'Shop List (Owned + Managed)',
  'tmp.name': 'Name',
  'tmp.handle': 'Handle',
  'tmp.search-by-name': 'Search by Name',
  'tmp.search-by-handle': 'Search by Handle',
  'tmp.bio': 'Bio',
  'tmp.upload-csv': 'Upload CSV',
  'tmp.upload-csv-hint1': 'Drop a file here or click to upload',
  'tmp.upload-csv-hint2': "Support a single '.csv' file",
  'tmp.upload-image': 'Upload Image',
  'tmp.upload-image-1:1': 'Crop & Upload (1:1)',
  'tmp.upload-image-16:9': 'Crop & Upload (16:9)',
  'tmp.upload-image-before-hint-title': "Acceptable image ratio -",
  'tmp.upload-image-before-hint-1:1': "Profile Photo, Product Cover & Product Image: <1:1>",
  'tmp.upload-image-before-hint-16:9': "Cover Photo: <16:9>",
  'tmp.upload-image-multiple-hint1': 'Drop files here or click to upload (Maximum 5 images per selection)',
  'tmp.upload-image-multiple-hint2': "Support '.jpg .jpeg .png .webp' files; All files will be converted to .jpg format; Filename will be recorded in lowercase",
  'tmp.upload-image-single-hint1': 'Drop a file here or click to upload',
  'tmp.upload-image-single-hint2': "Support a single '.jpg .jpeg .png .webp' file; File will be converted to .jpg format; Filename will be recorded in lowercase",
  'tmp.upload-limit-number':"Please select up to 5 images",
  'tmp.upload-limit-sidepixel':" pixels) is too small. Each side of the image cannot be smaller than 50 pixels",
  'tmp.upload-limit-sidepixel-crop':" pixels) is too small. Each side of the cropped image cannot be smaller than 50 pixels",
  'tmp.upload-error-samefilename-1': "number of images with the same filename '",
  'tmp.upload-error-samefilename-2': "' (case-insensitive) reaches the limit",
  'tmp.shop': 'Shop',
  'tmp.manager': 'Manager',
  'tmp.landmark': 'Landmark',
  'tmp.owner': 'Owner',
};
