import component from './zh-CN/component';
import globalHeader from './zh-CN/globalHeader';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';
import pwa from './zh-CN/pwa';
import settingDrawer from './zh-CN/settingDrawer';
import settings from './zh-CN/settings';

export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.preview.down.block': '下载此页面到本地项目',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  'app.name': '路路香港 商家',
  'tmp.language.english': 'English',
  'tmp.language.zh_Hans': '简体中文',
  'tmp.language.zh_Hant': '繁體中文',
  'tmp.welcome': '欢迎！',
  'tmp.dashboard': '概览',
  'tmp.captcha.title': '安全检查（点按图片来换个验证码）',
  'tmp.captcha.placeholder': '安全检查',
  'tmp.captcha.hint': '请输入验证码',
  'tmp.captcha.change.fail': '无法生成新的验证码',
  'tmp.login.success': '登录成功',
  'tmp.login.fail': '登录失败',
  'tmp.logout': '退出登录',
  'tmp.clear': '清除',
  'tmp.confirm': '确认',
  'tmp.okay': '确定',
  'tmp.bracket-open': '（',
  'tmp.bracket-close': '）',
  'tmp.no-permission': '没有权限',
  'tmp.upload-title': '上传',
  'tmp.upload-fail': "上传失败：",
  'tmp.upload-error-unexpected': "发生意外错误",
  'tmp.upload-success': " 上传成功",
  'tmp.update-success': "更新成功！",
  'tmp.password-change.success': "修改成功！",
  'tmp.password-change.fail': "无法修改密码",
  'tmp.password-change.new-not-match': "新密码不匹配",
  'tmp.password-change.old-password.placeholder': "当前密码",
  'tmp.password-change.new-password.placeholder': "新密码",
  'tmp.password-change.confirm-new-password.placeholder': "确认新密码",
  'tmp.password-change.must-fill': "此为必填项",
  'tmp.password-change.merchant-password-invalid': "密码必须至少有8个字符，而且至少包含以下其中两种字符：大写字母、小写字母、数字和符号。",
  'tmp.password-change.password-same-as-old': "新密码不能和当前密码相同",
  'tmp.password-change.wrong-password': "密码错误",
  'tmp.timepostedon': "发布在",
  'tmp.accessTokenExpired': "登录已过期，请重新登录。",
  'tmp.accessTokenExpiredTitle': "登录已过期",
  'tmp.pageNotFound': "抱歉，找不到网页",
  'tmp.goBackHome': "返回首页",
  'tmp.csv-merchant.msg': '商家 CSV 指南（简体中文）v1.0',
  'tmp.csv-shop.msg': '商店 CSV 指南（简体中文）v1.0',
  'tmp.csv-product.msg': '商品 CSV 指南（简体中文）v1.0',
  'tmp.csv-merchant.link': 'https://docs.luluhk.com/商家 CSV 导入和导出（简体中文）.pdf',
  'tmp.csv-shop.link': 'https://docs.luluhk.com/商店 CSV 导入和导出（简体中文）.pdf',
  'tmp.csv-product.link': 'https://docs.luluhk.com/商品 CSV 导入和导出（简体中文）.pdf',
  'tmp.import-export': '导入/导出',
  'tmp.import.merchant': '导入商家信息',
  'tmp.import.shop': '商店导入',
  'tmp.import.product': '商品导入',
  'tmp.export.merchant': '导出商家信息',
  'tmp.export.shop': '商店导出',
  'tmp.export.product': '商品导出',
  'tmp.shop-list': '商店列表（拥有 + 管理）',
  'tmp.name': '名称',
  'tmp.handle': '识别码',
  'tmp.search-by-name': '以名称搜索',
  'tmp.search-by-handle': '以识别码搜索',
  'tmp.bio': '简介',
  'tmp.upload-csv': '上传CSV文件',
  'tmp.upload-csv-hint1': '将文件拖到此处或点击来上传',
  'tmp.upload-csv-hint2': '支持一个 ".csv" 文件',
  'tmp.upload-image': '上传图片',
  'tmp.upload-image-1:1': '裁切和上传（1:1）',
  'tmp.upload-image-16:9': '裁切和上传（16:9）',
  'tmp.upload-image-before-hint-title': '支持的图片比例 -',
  'tmp.upload-image-before-hint-1:1': '头像、商品封面和商品图片：<1:1>',
  'tmp.upload-image-before-hint-16:9': '封面照片：<16:9>',
  'tmp.upload-image-multiple-hint1': '将文件拖到此处或点击来上传（每次最多选取5张图片）',
  'tmp.upload-image-multiple-hint2': '支持 ".jpg .jpeg .png .webp" 文件；所有文件将被转换成 .jpg 格式；文件名称将以小写记录',
  'tmp.upload-image-single-hint1': '将文件拖到此处或点击来上传',
  'tmp.upload-image-single-hint2': '支持一个 ".jpg .jpeg .png .webp" 文件；文件将被转换成 .jpg 格式；文件名称将以小写记录',
  'tmp.upload-limit-number':"最多选取5张图片",
  'tmp.upload-limit-sidepixel':" 像素）过小。图片每边不能小于50像素",
  'tmp.upload-limit-sidepixel-crop':" 像素）过小。裁切后的图片每边不能小于50像素",
  'tmp.upload-error-samefilename-1': "具有相同文件名称“",
  'tmp.upload-error-samefilename-2': "”（不区分大小写）的图片数量达到上限",
  'tmp.shop': '商店',
  'tmp.manager': '管理员',
  'tmp.landmark': '地标',
  'tmp.owner': '所有者',
};
