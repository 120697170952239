import component from './zh-TW/component';
import globalHeader from './zh-TW/globalHeader';
import menu from './zh-TW/menu';
import pages from './zh-TW/pages';
import pwa from './zh-TW/pwa';
import settingDrawer from './zh-TW/settingDrawer';
import settings from './zh-TW/settings';

export default {
  'navBar.lang': '語言',
  'layout.user.link.help': '幫助',
  'layout.user.link.privacy': '隱私',
  'layout.user.link.terms': '條款',
  'app.preview.down.block': '下載此頁面到本地項目',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  'app.name': '路路香港 商家',
  'tmp.language.english': 'English',
  'tmp.language.zh_Hans': '简体中文',
  'tmp.language.zh_Hant': '繁體中文',
  'tmp.welcome': '歡迎！',
  'tmp.dashboard': '概覽',
  'tmp.captcha.title': '安全檢查（點按圖片來換個驗證碼）',
  'tmp.captcha.placeholder': '安全檢查',
  'tmp.captcha.hint': '請輸入驗證碼',
  'tmp.captcha.change.fail': '無法產生新的驗證碼',
  'tmp.login.success': '登入成功',
  'tmp.login.fail': '登入失敗',
  'tmp.logout': '登出',
  'tmp.clear': '清除',
  'tmp.confirm': '確認',
  'tmp.okay': '確定',
  'tmp.bracket-open': '（',
  'tmp.bracket-close': '）',
  'tmp.no-permission': '沒有權限',
  'tmp.upload-title': '上傳',
  'tmp.upload-fail': "上傳失敗：",
  'tmp.upload-error-unexpected': "發生意外錯誤",
  'tmp.upload-success': " 上傳成功",
  'tmp.update-success': "更新成功！",
  'tmp.password-change.success': "修改成功！",
  'tmp.password-change.fail': "無法修改密碼",
  'tmp.password-change.new-not-match': "新密碼不一致",
  'tmp.password-change.old-password.placeholder': "目前的密碼",
  'tmp.password-change.new-password.placeholder': "新密碼",
  'tmp.password-change.confirm-new-password.placeholder': "確認新密碼",
  'tmp.password-change.must-fill': "此為必填項目",
  'tmp.password-change.merchant-password-invalid': "密碼必須至少有8個字元，而且至少包含以下其中兩種字元：大寫字母、小寫字母、數字和符號。",
  'tmp.password-change.password-same-as-old': "新密碼不能和目前的密碼相同",
  'tmp.password-change.wrong-password': "密碼錯誤",
  'tmp.timepostedon': "發佈於",
  'tmp.accessTokenExpired': "登入已過期，請重新登入。",
  'tmp.accessTokenExpiredTitle': "登入已過期",
  'tmp.pageNotFound': "抱歉，找不到網頁",
  'tmp.goBackHome': "返回首頁",
  'tmp.csv-merchant.msg': '商家 CSV 指南（繁體中文）v1.0',
  'tmp.csv-shop.msg': '商店 CSV 指南（繁體中文）v1.0',
  'tmp.csv-product.msg': '產品 CSV 指南（繁體中文）v1.0',
  'tmp.csv-merchant.link': 'https://docs.luluhk.com/商家 CSV 匯入和匯出（繁體中文）.pdf',
  'tmp.csv-shop.link': 'https://docs.luluhk.com/商店 CSV 匯入和匯出（繁體中文）.pdf',
  'tmp.csv-product.link': 'https://docs.luluhk.com/產品 CSV 匯入和匯出（繁體中文）.pdf',
  'tmp.import-export': '匯入/匯出',
  'tmp.import.merchant': '匯入商家資訊',
  'tmp.import.shop': '商店匯入',
  'tmp.import.product': '產品匯入',
  'tmp.export.merchant': '匯出商家資訊',
  'tmp.export.shop': '商店匯出',
  'tmp.export.product': '產品匯出',
  'tmp.shop-list': '商店清單（擁有 + 管理）',
  'tmp.name': '名稱',
  'tmp.handle': '識別碼',
  'tmp.search-by-name': '以名稱搜尋',
  'tmp.search-by-handle': '以識別碼搜尋',
  'tmp.bio': '簡介',
  'tmp.upload-csv': '上傳CSV文件',
  'tmp.upload-csv-hint1': '將文件拖到此處或點擊來上傳',
  'tmp.upload-csv-hint2': '支持一個 ".csv" 文件',
  'tmp.upload-image': '上傳圖片',
  'tmp.upload-image-1:1': '裁切和上傳（1:1）',
  'tmp.upload-image-16:9': '裁切和上傳（16:9）',
  'tmp.upload-image-before-hint-title': '支持的圖片比例 -',
  'tmp.upload-image-before-hint-1:1': '個人資料相片、產品封面和產品圖片：<1:1>',
  'tmp.upload-image-before-hint-16:9': '封面相片：<16:9>',
  'tmp.upload-image-multiple-hint1': '將文件拖到此處或點擊來上傳（每次最多選擇5張圖片）',
  'tmp.upload-image-multiple-hint2': '支持 ".jpg .jpeg .png .webp" 文件；所有文件將被轉換為 .jpg 格式；文件名稱將以小寫記錄',
  'tmp.upload-image-single-hint1': '將文件拖到此處或點擊來上傳',
  'tmp.upload-image-single-hint2': '支持一個 ".jpg .jpeg .png .webp" 文件；文件將被轉換為 .jpg 格式；文件名稱將以小寫記錄',
  'tmp.upload-limit-number':"最多選擇5張圖片",
  'tmp.upload-limit-sidepixel':" 像素）太小。圖片每邊不能小於50像素",
  'tmp.upload-limit-sidepixel-crop':" 像素）太小。裁切後的圖片每邊不能小於50像素",
  'tmp.upload-error-samefilename-1': "擁有相同文件名稱“",
  'tmp.upload-error-samefilename-2': "”（不區分大小寫）的圖片數量達到上限",
  'tmp.shop': '商店',
  'tmp.manager': '管理員',
  'tmp.landmark': '地標',
  'tmp.owner': '擁有者',
};
